@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro);
@font-face { font-family: "swiss"; src: url('http://static.tumblr.com/spzdac4/lJOm3fcw1/swiss.ttf');}
@font-face {font-family: "tattoo"; src: url('http://static.tumblr.com/q54tzar/oh2m2fe9d/vtc-nuetattooscript_1_.ttf');}
@font-face {font-family: "'sky metropolis'"; src: url('http://static.tumblr.com/bbylywf/ofhm8txx5/sky_metropolis.otf');}
@font-face { font-family: "bebas neue"; src: url('http://static.tumblr.com/cvlbtjz/cnsln9tpp/bebasneue.otf'); }
@font-face { font-family: "tall drink"; src: url('http://static.tumblr.com/8pcxx7s/v3zm1pqm5/tall_drink.ttf'); }
@font-face { font-family: "dear kelly"; src: url('http://static.tumblr.com/8pcxx7s/1OZm1pqmj/dear_kelly.ttf'); }
@font-face { font-family: "marker"; src: url('http://themes.googleusercontent.com/static/fonts/permanentmarker/v0/9vYsg5VgPHKK8SXYbf3sMupm9E4gBdgiUiSJePew_SQ.woff'); }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.products-more,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.product-detail {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  text-justify: center;
  font-size: 3rem;
}

.exec-summary {
  background-image: url('./images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.whitepaper {
  background-image: url('./images/img-10.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.products {
  background-image: url('./images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.product-detail {
  background-image: url('./images/img-3.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: yellow;
  font-size: 100px;
}

.sign-up {
  background-image: url('./images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}