@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro);
@font-face { font-family: "swiss"; src: url('http://static.tumblr.com/spzdac4/lJOm3fcw1/swiss.ttf');}
@font-face {font-family: "tattoo"; src: url('http://static.tumblr.com/q54tzar/oh2m2fe9d/vtc-nuetattooscript_1_.ttf');}
@font-face {font-family: "'sky metropolis'"; src: url('http://static.tumblr.com/bbylywf/ofhm8txx5/sky_metropolis.otf');}
@font-face { font-family: "bebas neue"; src: url('http://static.tumblr.com/cvlbtjz/cnsln9tpp/bebasneue.otf'); }
@font-face { font-family: "tall drink"; src: url('http://static.tumblr.com/8pcxx7s/v3zm1pqm5/tall_drink.ttf'); }
@font-face { font-family: "dear kelly"; src: url('http://static.tumblr.com/8pcxx7s/1OZm1pqmj/dear_kelly.ttf'); }
@font-face { font-family: "marker"; src: url('http://themes.googleusercontent.com/static/fonts/permanentmarker/v0/9vYsg5VgPHKK8SXYbf3sMupm9E4gBdgiUiSJePew_SQ.woff'); }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.products-more,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.product-detail {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  text-justify: center;
  font-size: 3rem;
}

.exec-summary {
  background-image: url(/static/media/img-2.bb238fc1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.whitepaper {
  background-image: url(/static/media/img-10.bb33b506.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.products {
  background-image: url(/static/media/img-1.53f48437.jpg);
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.product-detail {
  background-image: url(/static/media/img-3.6d683c0e.jpg);
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: yellow;
  font-size: 100px;
}

.sign-up {
  background-image: url(/static/media/img-8.fabdf94d.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .cartallum-logo {
    color:orange;
    height: 50px;
    width: 175px;
    justify-self: start;
    margin-left: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .navbar-logo {
    color: orange;
    font-family: 'bebas neue';
    font-weight: bold;
    font-size: 2rem;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
    font-family: 'bebas neue';
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: 'bebas neue';
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
    font-family: 'bebas neue';
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: translate(25%, 50%);
              transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translate(-100%, 60%);
              transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }
video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
  
  .cart-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    font-family: 'bebas neue';
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .cart-container > h1 {
    font-family: 'bebas neue';
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
  }
  
  .cart-container > p {
    font-family: 'bebas neue';
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .cart-btns {
    font-family: 'bebas neue';
    margin-top: 32px;
  }
  
  .cart-btns .btn {
    font-family: 'bebas neue';
    margin: 6px;
  }
  
  .fa-play-circle {
    font-family: 'bebas neue';
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .cart-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .cart-container > h1 {
      font-family: 'bebas neue';
      font-size: 50px;
      margin-top: -100px;
    }
  
    .cart-container > p {
      font-family: 'bebas neue';
      font-size: 30px;
    }
  
    .btn-mobile {
      font-family: 'bebas neue';
      display: block;
      text-decoration: none;
    }
  
    .btn {
      font-family: 'bebas neue';
      width: 100%;
    }
  }
.cards {
    padding: 4rem;
    background: #fff;
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__iframe {
    display: relative;
    flex: 1 1;
    align-items: top;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    -webkit-animation-name: fade-img;
            animation-name: fade-img;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
.footer-container {
    font-family: 'bebas neue';
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .cartallum-logo {
    font-family: 'bebas neue';
    color:orange;
    height: 50px;
    width: 275px;
    justify-self: start;
    margin-left: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }

  .footer-subscription {
    font-family: 'bebas neue';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: orangered;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif, 'bebas neue';
    }
  
  .footer-subscription-heading {
    font-family: 'bebas neue';
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    font-family: 'bebas neue';
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    font-family: 'Lucida Sans Unicode';
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 14px;
    margin-bottom: 16px;
    border: 1px solid orange;
  }
  
  .footer-links {
    font-family: 'bebas neue';
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    font-family: 'bebas neue';
    display: flex;
  }
  
  .footer-link-items {
    font-family: 'bebas neue';
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: orange;
  }
  
  .footer-link-items h2 {
    font-family: 'bebas neue';
    margin-bottom: 16px;
    color: orangered;
  }
  
  .footer-link-items > h2 {
    font-family: 'bebas neue';
    color: coral;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: orange;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    font-family: 'bebas neue';
    margin-bottom: 2rem;
    color: coral;
  }
  
  .footer-input::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  
  .footer-input:-ms-input-placeholder {
    color: #b1b1b1;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 10%;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 768px) {
  }
